.LoginContainer{
    max-width: 350px;
    width: 100%;
}
.LoginTitle {
    font-size: 24px;
    font-weight: 600;
    color: #191919;
    text-align: center;
    margin-bottom: 50px;
}
.IDWrap {
    margin-bottom: 25px;
}
.IDWrap .IDLabel {
    font-size: 18px;
    font-weight: 600;
    color: #191919;
    margin-bottom: 10px;
    margin-left: 5px;
}
.IDWrap .IDInput {
    padding: 8px 12px;
    border-radius: 10px;
    border: 1px solid #B1B1B1;
}
.PWWrap {
    position: relative;
    margin-bottom: 45px;
}
.PWWrap .PWLabel {
    font-size: 18px;
    font-weight: 600;
    color: #191919;
    margin-bottom: 10px;
    margin-left: 5px;
}
.PWWrap .PWInput {
    padding: 8px 12px;
    border-radius: 10px;
    border: 1px solid #B1B1B1;
}
.LoginBtn {
    font-size: 16px;
    font-weight: 600;
    color: #191919;
    padding: 8px 12px;
    border-radius: 10px;
    border: 1px solid #B1B1B1;
    cursor: pointer;
}
.LoginBtn:hover {
    font-weight: 800;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.Eyes {
    position: absolute;
    top: 36px;
    right: 14px;
}