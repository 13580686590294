/** Common */
.AppContainer {
    width: 430px;
    margin: 0 auto;
    padding: 20px 0;
}
.Container {
    padding: 10px 20px;
}
.ListNull {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
    color: #191919;
}
/** Abbreviation */
.DF-Row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.DF-Col {
    display: flex;
    flex-direction: column;
}
.MR-05 {
    margin-right: 5px;
}
.MR-10 {
    margin-right: 10px;
}
.PL-7 {
    padding-left: 7px;
}
.DN {
    display: none;
}
.CP {
    cursor: pointer;
}