
.Result .List {
    background-color: #fff;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
    padding: 10px 15px;
    margin-top: 5px;
    margin-bottom: 20px;
    cursor: pointer;
}
.Result .ListHeader {
    margin-bottom: 10px;
    justify-content: space-between;
}
.Result .Status {
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
    padding: 5px;
    margin-right: 5px;
}
.Result .blue {
    color: #FFF;
    background-color: #5815FF;
    border: 1px solid #5815FF;
}
.Result .green {
    color: #FFF;
    background-color: #1ECB19;
    border: 1px solid #1ECB19;
}
.Result .gray {
    color: #FFF;
    background-color: #9A9A9A;
    border: 1px solid #9A9A9A;
}
.Result .white {
    color: #9B9B9B;
    background-color: #FFF;
    border: 1px solid #9B9B9B;
}

.Result .RegistDate {
    font-size: 10px;
    font-weight: bold;
    color: #B1B1B1;
}
.Result .Option {
    font-size: 12px;
    font-weight: 900;
    color: #B1B1B1;
}
.Result .ContentWrap {
    margin-bottom: 10px;
}
.Result .Content {
    font-size: 14px;
    font-weight: 600;
    color: #191919;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap; 
}
.Result .ContentOpen {
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1.25em;
    font-size: 14px;
    font-weight: 600;
    color: #191919;
    transition: all 0.2s ease-out; 
}
.Result .ChevronDown {
    text-align: center;
    margin-top: 10px;
}

.Result .DateTimeAmount {
    justify-content: space-between;
    margin-bottom: 10px;
}
.Result .Date {
    padding: 5px 7px;
    font-size: 10px;
    font-weight: bold;
    color: #747474;
    background-color: #fff;
    border: 1px solid #DBDBDB;
    margin-right: 5px;
}
.Result .Time {
    padding: 5px 7px;
    font-size: 10px;
    font-weight: bold;
    color: #747474;
    background-color: #fff;
    border: 1px solid #DBDBDB;
}
.Result .Amount {
    font-size: 12px;
    font-weight: bold;
    color: #9A9A9A;
    background-color: #EDEDED;
    border-radius: 9px;
    text-align: center;
    padding: 5px 7px;
}
.Result .NamePhone {
    background-color: #EDEDED;
    border-radius: 10px;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    color: #747474;
    cursor: pointer;
}
.Result .NamePhone:hover {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    color: #191919;
}
.Result .SelectOption {
    width: 100%;
    padding: 5px 0;
    font-size: 14px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
}
.Result .SelectOpen {
    animation: OpenGate 0.2s;
}
@keyframes OpenGate {
    0% {
        margin-top: -20px;
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
}
.Result .SelectClose {
    display: none;
}

.Result .SelectBtn {
    width: 100%;
    padding: 5px 0;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #DBDBDB;
    margin-top: 10px;
    cursor: pointer;
}
.Result .SelectBtn:hover {
    font-weight: 600;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}