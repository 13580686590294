.Navbar .CategoryWrap {
    justify-content: space-between;
}
.Navbar .WelcomeWrap {
    justify-content: space-between;
    margin-bottom: 10px;
}
.Navbar .Welcome {
    font-size: 18px;
    font-weight: 600;
}
.Navbar .LogoutBtn {
    font-size: 14px;
    font-weight: 600;
    color: #191919;
    padding: 5px 15px;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
    cursor: pointer;
}
.Navbar .LogoutBtn:hover {
    font-weight: 800;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.Navbar .Category {
    width: 90px;
    padding: 12px 0;
    border-radius: 10px;
    justify-content: center;
    cursor: pointer;
}
.Navbar .dataTotalNum {
    width: 100%;
    padding: 12px 0;
    border-radius: 10px;
    font-weight: 600;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #191919;
    background-color: #FFF;
}
.Navbar .Ct01 {
    background-color: #5815FF;
    color: #FFF;
    border: 1px solid #5815FF;
}
.Navbar .Ct02 {
    background-color: #1ECB19;
    color: #FFF;
    border: 1px solid #1ECB19;
}
.Navbar .Ct03 {
    background-color: #9A9A9A;
    color: #FFF;
    border: 1px solid #9A9A9A;
}
.Navbar .Ct04 {
    background-color: #FFFFFF;
    color: #B1B1B1;
    border: 1px solid #B1B1B1;
}

/** Date */
.Navbar .DateOptionWrap {
    background-color: #F4F4F4;
}
.Navbar .DateWrap {
    position: relative;
    justify-content: space-between;
}
.Navbar .Date {
    justify-content: center;
    width: 190px;
    height: 52px;
    border-radius: 10px;
    border: 1px solid #DBDBDB;
    background-color: #fff;
}
.Navbar .Label {
    font-size: 14px;
    font-weight: 400;
    color: #191919;
}
.Navbar .SelectedDate {
    font-size: 14px;
    font-weight: 400;
    color: #747474;
}
.Navbar .SelectedDate:hover {
    font-weight: 600;
    color: #191919;
    cursor: pointer;
}

.Navbar .OptionWrap {
    margin-top: 10px;
    justify-content: space-between;
}
.Navbar .Option {
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #747474;
    background-color: #fff;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
}
.Navbar .Op01 {
    width: 190px;
}
.Navbar .Op02 {
    width: 90px;
}
.Navbar .Op03 {
    height: 32px;
    width: 182px;
}
.Navbar .DatePickWrap {
    display: flex;
    position: absolute;
    top: 60px;
    left: 50%;
    Transform: translate(-50%);
    flex-direction: column;
    align-items: center;
    /* width: 300px;
    height: 250px; */
    padding: 20px 20px;
    border: 1px solid #000;
    border-radius: 25px;
    background-color: #fff;
}